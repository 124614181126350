<template>
  <ConsolidationDetailUser
      :consolidation="consolidation"
      @print="printPage"
  />
</template>

<script>
  import ConsolidationDetailUser from "./ConsolidationDetailUser/ConsolidationDetailUser";
  import {Consolidation} from "../../models/Consolidation";
  import {consolidationMixin} from "../../../../../mixins/consolidationMixins/consolidationMixin";
  import {proformMixins} from "../../../../../mixins/proformMixins/proformMixins";

  export default {
    name: "ConsolidationDetail",
    components: {
      ConsolidationDetailUser,
    },

    mixins: [
        consolidationMixin,
        proformMixins
    ],

    watch: {
      downloadPermissions() {
        this.checkPermission()
      },
    },

    created() {
      if(this.downloadPermissions){
        if(!this.checkPermission()) return
      }

      this.initializeProductProformArray(this.consolidation.data.proformOrders).then(() => {
        this.initConsolidationShow()
      })
    },

    data() {
      return {
        consolidation: new Consolidation(),
      }
    },

    methods: {
      initConsolidationShow() {
        this.$store.dispatch('getConsolidationCreate').then(response => {

          let destinationData = this.getRespData(response)?.destinations
          this.$store.dispatch('getConsolidation', this.$route.params.id).then((response) => {
            console.log('show consolidation',response);
            this.consolidation.setConsolidationItem(this.$store.getters.getConsolidationItem, destinationData, this)
          }).catch(error => this.createErrorLog(error))
        })
      },

      printPage() {
        window.print()
      },
    }
  }
</script>

<style scoped>

</style>

<style media="print">

.print-btn-head{
  max-width: 167px;
}

@page { size: portrait; }

@media print {
  * {
    -webkit-print-color-adjust: exact;
  }
  body .noPrintable {
    display: none !important;
  }

  .default-layout__page-wrap{
    padding-left: 0 !important;
  }
  .manager-btn{
    display: none !important;
  }

  .detail-cards__section, .detail-cards__aside {
    margin-bottom: 0;
  }

  .site-table-wrap.table-small .site-table {
    min-width: unset;
  }

  .cellsForPrint {
    display: inline !important;
    word-break: keep-all;
  }

  .forPrint {
    display: table-cell !important;
  }
}
</style>